import {useDispatch, useSelector} from 'react-redux';
import * as Styles from './styles';
import RangeSlider from 'react-bootstrap-range-slider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCompress, faExpand, faVolumeDown, faVolumeMute, faVolumeOff, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import {toHHMMSS} from '../../../../utils/time';
import {selectPlayerInfo, setLastVolume, setVolume} from '../../../../store/player/player.slice';
import TimeSlider from './components/TimeSlider';
import {Question} from '../../../../types/question';

interface ControlsProps {
  toggleFullScreen: () => void;
  questions: Question[];
}

const Controls = ({toggleFullScreen, questions}: ControlsProps) => {
  const dispatch = useDispatch();
  const {volume, lastVolume, sliderValue, video, viewMode} = useSelector(selectPlayerInfo);
  const isFullScreen = viewMode === 'fullscreen';

  const toggleMute = () => {
    //If is mute, return to the last volume
    if (volume === 0) {
      dispatch(setLastVolume(lastVolume));
    } else {
      dispatch(setVolume(0));
    }
  };

  return (
    <Styles.VideoControlsContainer>
      <TimeSlider questions={questions} />
      <Styles.Controls className='mt-2'>
        <div className='start'>
          <Styles.VolumeContainer>
            <div onClick={toggleMute}>
              {volume === 0 ? (
                /* Volume 0 icon */
                <FontAwesomeIcon icon={faVolumeMute} />
              ) : volume > 0 && volume < 33 ? (
                /* Volume off icon */
                <FontAwesomeIcon icon={faVolumeOff} />
              ) : volume >= 33 && volume < 66 ? (
                /* Volume low icon */
                <FontAwesomeIcon icon={faVolumeDown} />
              ) : (
                /* Volume high icon */
                <FontAwesomeIcon icon={faVolumeUp} />
              )}
            </div>
            <RangeSlider className='volume-slider' value={volume} tooltip='off' onChange={(e) => dispatch(setVolume(parseInt(e.target.value)))} onAfterChange={(e) => setLastVolume(parseInt(e.target.value))} min={0} max={100} />
          </Styles.VolumeContainer>
          <Styles.DurationContainer>
            {toHHMMSS(sliderValue || 0)} / {toHHMMSS(video?.duration || 0)}
          </Styles.DurationContainer>
        </div>
        <div className='d-flex flex-row align-items-center gap-2'>
          {/* Full screen button */}
          <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} className='action-btn' onClick={toggleFullScreen} />
        </div>
      </Styles.Controls>
    </Styles.VideoControlsContainer>
  );
};

export default Controls;

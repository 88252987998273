import {useVideo} from '../../StudentVideo';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {selectProlificId} from '../../../../store/auth/auth.slice';
import {useEffect} from 'react';

const PreTest = () => {
  const {video} = useVideo();

  const prolificId = useSelector(selectProlificId);
  const videoPageURL = `/student/watch/${video.id}`;
  const pretestURL = video.pretestURL ? video.pretestURL + '?PROLIFIC_PID=' + prolificId : undefined;
  const navigate = useNavigate();
  const iframeStyle = {
    width: '100%',
    height: '100vh',
  };

  // If the pretest URL is not available, redirect to the video page
  useEffect(() => {
    if (!pretestURL) navigate(videoPageURL, {replace: true});
  }, [pretestURL, navigate, videoPageURL]);

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Pre-Test</h2>
          <p>Take this quiz to test your knowledge before watching the video</p>
          <div className='mb-4' />
          {pretestURL && <iframe style={iframeStyle} id='pre-test-iframe' title='Pre-test' width='100%' height='100%' src={pretestURL} />}
          <div className='d-flex justify-content-center'>
            <Link to={videoPageURL}>
              <Button variant='success' size='lg'>
                Click me when you finish the pre-test
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PreTest;

import {Col, Container, Row} from 'react-bootstrap';
import {selectProlificId} from '../../../../store/auth/auth.slice';
import {useSelector} from 'react-redux';
import {useVideo} from '../../StudentVideo';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const PostTest = () => {
  const {video} = useVideo();
  const prolificId = useSelector(selectProlificId);
  const posttestURL = video.posttestURL ? video.posttestURL + '?PROLIFIC_PID=' + prolificId : undefined;
  const navigate = useNavigate();
  const homePageURL = '/student';

  const iframeStyle = {
    width: '100%',
    height: '100vh',
  };

  // If the pretest URL is not available, redirect to home page
  useEffect(() => {
    if (!posttestURL) navigate(homePageURL, {replace: true});
  }, [posttestURL, navigate, homePageURL]);

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Post-Test</h2>
          <p>Take this quiz to test your knowledge after watching the video</p>
          <div className='mb-4' />
          {posttestURL && <iframe style={iframeStyle} id='post-test-iframe' title='Post-test' width='100%' height='100%' src={posttestURL} />}
        </Col>
      </Row>
    </Container>
  );
};

export default PostTest;

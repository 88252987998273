import React, {useEffect, useState} from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Button, Col, Container, Form, Row, Table} from 'react-bootstrap';
import InputContainer from '../../components/form-control/InputContainer';
import Loading from '../../components/loading/Loading';
import useError from '../../hooks/useError';
import {useLoginMutation} from '../../store/auth/auth.api';
import {useDispatch, useSelector} from 'react-redux';
import {resetProlificId, selectIsUserLogged} from '../../store/auth/auth.slice';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsUserLogged);

  const [login, {isLoading, isError, error}] = useLoginMutation();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [consentStatus, setConsentStatus] = useState<number>(-1); // -1: not answered, 0: not consent, 1: consent
  const [videoId, setVideoId] = useState<string>('');
  const [prolificId, setProfilicId] = useState<string>('');
  const buttonDisabled = !username || !password;

  const infoSheetURI = process.env.REACT_APP_FILES_URL + 'Participant_Information_Sheet.pdf';
  const noConsentURL = 'https://app.prolific.com/submissions/complete?cc=CWWDI3LG';

  useEffect(() => {
    const videoId = searchParams.get('videoId');
    const profilicIdParam = searchParams.get('PROLIFIC_PID');
    if (videoId) setVideoId(videoId);
    if (profilicIdParam) {
      setProfilicId(profilicIdParam);
    }
  }, [searchParams, dispatch]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!buttonDisabled) {
      login({emailOrUsername: username, password, prolificId})
        .unwrap()
        .then(() => {
          // Reset the prolific id
          dispatch(resetProlificId({prolificId}));

          // Redirect to the video page
          if (videoId) navigate(`/student/watch/${videoId}/pre-test`);
          else {
            // Redirect to the previous page
            const from = (location.state as any)?.from || '/student';
            navigate(from);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const errorMessage = useError({isError, error});

  if (isLoading) return <Loading />;
  return (
    <>
      <Navbar />
      <Container className='py-5 page-container'>
        <Row>
          <Col sm={12} md={{span: 6, offset: 3}}>
            <h1 className='text-center'>Welcome to SmartQuiz</h1>
            <p className='text-center'>SmartQuiz is a platform to create and play quizzes from a YouTube video.</p>
            {/* Spacer */}
            <div className='mb-4'></div>
            {isLogged ? (
              <div className='text-center'>
                <Link to='student'>
                  <Button variant='primary' size='lg'>
                    Go to dashboard
                  </Button>
                </Link>
              </div>
            ) : (
              <Form onSubmit={onSubmit}>
                <h2>Consent form</h2>
                <p>
                  This project has been approved by the University of South Australia’s Human Research Ethics Committee. If you have any ethical concerns about the project, or questions about your rights as a participant, please contact the Executive Officer of this Committee, Tel: +61 8 8302 6330; Email: <Link to='mailto:humanethics@unisa.edu.au'> humanethics@unisa.edu.au</Link>
                </p>
                <strong>Section 1: Contact and project details</strong>
                <Table>
                  <tbody>
                    <tr>
                      <td>Researcher’s Full Name</td>
                      <td>Daniel Ebbert</td>
                    </tr>
                    <tr>
                      <td>Contact Details</td>
                      <td>+61 435 976 012 / daniel.ebbert@mymail.unisa.edu.au</td>
                    </tr>
                    <tr>
                      <td>Supervisor’s Name</td>
                      <td>Negin Mirriahi</td>
                    </tr>
                    <tr>
                      <td>Contact Details</td>
                      <td>+61 8 830 20791 / Negin.Mirriahi@unisa.edu.au</td>
                    </tr>
                    <tr>
                      <td>Project Number</td>
                      <td>206353</td>
                    </tr>
                    <tr>
                      <td>Project Title</td>
                      <td>SmartQuiz: Evaluating AI-Enhanced Video Player for Automated Quiz Generation</td>
                    </tr>
                  </tbody>
                </Table>
                <strong>Section 2: Participant Certification</strong>
                <p>
                  In signing this form, I confirm that:
                  <ul>
                    <li>
                      I have read the{' '}
                      <Link to={infoSheetURI} target='_blank'>
                        Participant Information Sheet
                      </Link>
                      , and the nature and the purpose of the research project has been explained to me. I understand and agree to take part.
                    </li>
                    <li>I understand the nature of my involvement in the project.</li>
                    <li>I understand that I may not directly benefit from taking part in the project.</li>
                    <li>I understand that I can withdraw from the project at any stage and that this will not affect my status now or in the future.</li>
                    <li>I confirm that I am over 18 years of age.</li>
                    <li>I understand that information gained during the project may be published, and that all published information will be non-identifiable. No information that could lead to my identification will be released, unless required by law.</li>
                    <li>I understand that, on completion of the project, all data will be stored electronically on the secure UniSA Research Data Storage infrastructure and any hard copies secured in a locked cabinet at C3L offices at UniSA, with access to stored data restricted to the research team only. Data will be retained for a minimum of 5 years after publication and will be securely deleted / destroyed once it is no longer considered to have research value.</li>
                    <li>I understand that non-identifiable data collected in this project may be used in future projects, for which ethics approval will be sought.</li>
                    <li>I understand the statement in the information sheet concerning payment to me for taking part in the study.</li>
                  </ul>
                </p>
                <div className='mb-4'>
                  <Form.Check type='radio' label='Consent' name='form-consent' checked={consentStatus === 1} onChange={() => setConsentStatus(1)} />
                  <Form.Check type='radio' label='Not consent' name='form-consent' checked={consentStatus === 0} onChange={() => setConsentStatus(0)} />
                </div>

                {/* If not consent, display the button to go back in prolific */}
                {!consentStatus && (
                  <Alert variant='danger'>
                    As you have indicated that you do not consent to participate in this study, please return to Prolific by clicking on this <Link to={noConsentURL}>link</Link>.
                  </Alert>
                )}

                {/* Display the login only if the consent is given */}
                {consentStatus === 1 && (
                  <>
                    <h2>Login</h2>
                    <p>Please, insert the provided username and password to login.</p>
                    {/* Prolific ID */}
                    <InputContainer formGroupClassName='mb-4' label='Prolific ID' id='prolific-id'>
                      <Form.Control id='prolific-id' autoComplete='prolific-id' type='text' placeholder='Enter Prolific ID' value={prolificId} onChange={(e) => setProfilicId(e.target.value)} />
                    </InputContainer>
                    {/* Username */}
                    <InputContainer formGroupClassName='mb-4' label='Username' id='username'>
                      <Form.Control id='username' autoComplete='username' type='text' placeholder='Enter username' value={username} onChange={(e) => setUsername(e.target.value)} />
                    </InputContainer>
                    {/* Password */}
                    <InputContainer formGroupClassName='mb-4' label='Password' id='password'>
                      <Form.Control id='password' autoComplete='current-password' type='password' placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </InputContainer>
                    {/* Error message */}
                    {isError && <Alert variant='danger'>{errorMessage}</Alert>}
                    {/* Submit */}
                    <div className='text-center'>
                      <Button variant='primary' type='submit' disabled={buttonDisabled}>
                        Submit
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Home;

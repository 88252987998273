import {InVideoQuestion, ThoughtQuestion, TopicQuestion} from '../../types/dtos';
import {Question} from '../../types/question';
import {MessageResponse} from '../../types/response';
import {apiSlice} from '../api/api.slice';
import {CreateStudentAnswerRequest, DeleteQuestionRequest, RateThoughtQuestionsRequest} from './questions.api-types';

const questionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateQuestions: builder.mutation<InVideoQuestion[], string>({
      query: (videoId) => ({
        url: `questions/generate/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'InVideoQuestions', id: videoId}],
    }),
    generateQuestionsFromTopics: builder.mutation<InVideoQuestion[], string>({
      query: (videoId) => ({
        url: `questions/generate-from-topics/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'TopicQuestions', id: videoId}],
    }),
    //Generate questions from thoughts
    generateQuestionsFromThoughts: builder.mutation<ThoughtQuestion[], string>({
      query: (videoId) => ({
        url: `questions/generate-from-thoughts/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'ThoughtQuestions', id: videoId}],
    }),
    //Create student answer
    createStudentAnswer: builder.mutation<MessageResponse, CreateStudentAnswerRequest>({
      query: ({videoId, answerId}) => ({
        url: `questions/student-answer/${answerId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, args) => [{type: 'InVideoQuestions', id: args.videoId}],
    }),
    //Create topic question answer
    createStudentTopicQuestionAnswer: builder.mutation<MessageResponse, CreateStudentAnswerRequest>({
      query: ({videoId, answerId}) => ({
        url: `questions/topic-answer/${answerId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, args) => [{type: 'InVideoQuestions', id: args.videoId}],
    }),
    //Create personalized answer
    createPersonalizedAnswer: builder.mutation<MessageResponse, CreateStudentAnswerRequest>({
      query: ({videoId, answerId}) => ({
        url: `questions/personalized-answer/${answerId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, args) => [{type: 'ThoughtQuestions', id: args.videoId}],
    }),
    //Rate a thought question
    rateThoughtQuestion: builder.mutation<MessageResponse, RateThoughtQuestionsRequest>({
      query: ({questionId, isPositive, badReason, badComment}) => ({
        url: `questions/rate-thought-question/${questionId}`,
        method: 'POST',
        body: {isPositive, badComment, badReason},
      }),
    }),
    //Get questions to ask
    getQuestionsToAsk: builder.query<Question[], string>({
      query: (videoId) => `questions/questions-for-students/${videoId}`,
    }),
    //Get video questions
    getVideoQuestions: builder.query<InVideoQuestion[], string>({
      query: (videoId) => `questions/video/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'InVideoQuestions', id: videoId}],
    }),
    //Get topic questions
    getTopicQuestions: builder.query<TopicQuestion[], string>({
      query: (videoId) => `questions/topic-questions/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'TopicQuestions', id: videoId}],
    }),
    //Get thoughts questions
    getThoughtQuestions: builder.query<ThoughtQuestion[], string>({
      query: (videoId) => `questions/thought-questions/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'ThoughtQuestions', id: videoId}],
    }),
    //Get video questions for student
    getVideoQuestionsForStudent: builder.query<ThoughtQuestion[], string>({
      query: (videoId) => `questions/video/student/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'ThoughtQuestions', id: videoId}],
    }),
    deleteQuestion: builder.mutation<MessageResponse, DeleteQuestionRequest>({
      query: ({questionId, videoId}) => ({
        url: `questions/delete/${questionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'InVideoQuestions', id: arg.videoId}],
    }),
    //Delete all questions for video
    deleteAllQuestionsForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `questions/delete-all/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'InVideoQuestions', id: videoId}],
    }),
    //Delete all topic questions for video
    deleteAllTopicQuestionsForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `questions/delete-all-topic-questions/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'TopicQuestions', id: videoId}],
    }),
    //Delete all thought questions for video
    deleteAllThoughtQuestionsForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `questions/delete-all-thoughts-questions/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'ThoughtQuestions', id: videoId}],
    }),
    //Delete student answer for video
    deleteStudentAnswerForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `questions/delete-student-answers/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'InVideoQuestions', id: videoId}],
    }),
  }),
});

export const {
  useGenerateQuestionsMutation,
  useGenerateQuestionsFromTopicsMutation,
  useCreateStudentAnswerMutation,
  useCreatePersonalizedAnswerMutation,
  useRateThoughtQuestionMutation,
  useGetQuestionsToAskQuery,
  useGetVideoQuestionsQuery,
  useGetTopicQuestionsQuery,
  useGetVideoQuestionsForStudentQuery,
  useDeleteQuestionMutation,
  useDeleteAllQuestionsForVideoMutation,
  useDeleteStudentAnswerForVideoMutation,
  useDeleteAllTopicQuestionsForVideoMutation,
  useDeleteAllThoughtQuestionsForVideoMutation,
  useGenerateQuestionsFromThoughtsMutation,
  useGetThoughtQuestionsQuery,
} = questionsApiSlice;

import React, {useEffect, useMemo} from 'react';
import * as Styles from './styles';
import {Alert, Button, Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useCreatePersonalizedAnswerMutation} from '../../../../../../store/questions/questions.api';
import toast from 'react-hot-toast';
import {getMessageFromError} from '../../../../../../utils/errors';
import {popAllQuestions, removeQuestion, seekTo, selectActiveQuestions, selectPlayerInfo} from '../../../../../../store/player/player.slice';
import {Answer, Question} from '../../../../../../types/question';
import {Video} from '../../../../../../types/dtos';

interface Props {
  question: Question;
}

const QuestionItem = ({question}: Props) => {
  const dispatch = useDispatch();
  const video = useSelector(selectPlayerInfo).video as Video;
  const [selectedAnswer, setSelectedAnswer] = React.useState<Answer | undefined>();
  const activeQuestions = useSelector(selectActiveQuestions);
  const nextButtonText = useMemo(() => (activeQuestions.length > 1 ? 'Next' : 'Close'), [activeQuestions.length]);

  const [selectAnswer] = useCreatePersonalizedAnswerMutation();

  const onClickWatchVideo = () => {
    dispatch(seekTo(question.taggedSecond));
    dispatch(popAllQuestions());
  };

  //Shuffle answers
  const answers = useMemo(() => {
    const answers = [...question.answers];
    return answers.sort(() => Math.random() - 0.5);
  }, [question.answers]);

  const getClassName = (answer: Answer) => {
    if (!selectedAnswer) return '';
    if (answer.isCorrect) return 'selected-correct';
    if (answer.id === selectedAnswer.id && !answer.isCorrect) return 'selected-wrong';
  };

  const onClickAnswer = (answer: Answer) => {
    if (!selectedAnswer) setSelectedAnswer(answer);

    selectAnswer({videoId: video.id, answerId: answer.id})
      .unwrap()
      .catch((err) => toast.error(getMessageFromError(err)));
  };

  const onClickNextOrClose = () => {
    dispatch(removeQuestion(question.id));
    setSelectedAnswer(undefined);
  };

  useEffect(() => {
    question.answers.forEach((answer) => {
      if (answer.studentAnswers?.length && answer.studentAnswers?.length > 0) {
        setSelectedAnswer(answer);
      }
    });
  }, [question]);

  return (
    <Container>
      <Styles.Question>{question.text}</Styles.Question>
      {answers.map((answer) => (
        <Styles.Answer disabled={selectedAnswer !== undefined} className={getClassName(answer)} key={answer.id} onClick={() => onClickAnswer(answer)}>
          {answer.text}
        </Styles.Answer>
      ))}
      {selectedAnswer && (
        <>
          <Alert variant={selectedAnswer.isCorrect ? 'success' : 'danger'}>{selectedAnswer.feedback}</Alert>
          {/* Seek back to the video moment to which the question is tagged */}
          <div className='d-flex flex-row justify-content-between gap-2'>
            <Button variant='info' onClick={onClickWatchVideo}>
              Watch related video
            </Button>
            {/* Button: if there another question then "Next", otw "Close" */}
            <Button variant='success' onClick={onClickNextOrClose}>
              {nextButtonText}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default QuestionItem;
